import { ChangeEvent, useState, MouseEvent } from "react";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import styles from "./WbNdsNotice.module.scss";
import { ServerErrors } from "src/models/Models";
import { toast } from "react-toastify";
import axios from "axios";
import { ParseNdsNoticeFileResponse } from "src/models/NdsNoticeModels";
import {parseWbNdsNotice} from "../../../services/NdsNoticeApiService";
import NdsDetails from "../NdsDetails/NdsDetails";

interface TProps {
  onChange: (
    file: File,
    isLegalEntity: boolean,
    isAddressMissing: boolean
  ) => void;
  token: string;
  disabled?: boolean;
  trueApiToken?: string;
  inn: string;
}

export default function WbNdsNotice({
  onChange,
  disabled,
  token,
  trueApiToken,
  inn
}: TProps) {
  const [fileName, setFileName] = useState<string | null>(null);
  const [parsedDetails, setParsedDetails] =
    useState<ParseNdsNoticeFileResponse | null>(null);

  const [isParsing, setIsParsing] = useState(false);

  const handleFileOnChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;

    if (!file || !trueApiToken) return;

    setFileName(file.name);

    try {
      setIsParsing(true);

      let response = await parseWbNdsNotice(file, token, trueApiToken!, inn);
      setParsedDetails(response);
      onChange(
        file,
        response.isLegalEntity,
        response.isAddressMissing,
      );
    } catch (error) {
      if (axios.isAxiosError<ServerErrors>(error)) {
        toast.error(`${error.response?.data.errors.join("; ")}`);
      } else {
        console.error(error);
      }
      setParsedDetails(null);
    } finally {
      setIsParsing(false);
    }
  };

  const clearHtmlInput = async (event: MouseEvent<HTMLInputElement>) => {
    event.currentTarget.value = "";
  };

  return (
    <div>
      <Button
        component="label"
        variant="outlined"
        startIcon={<CloudUploadIcon />}
        fullWidth
        className={styles.fileButton}
        disabled={disabled}
      >
        {fileName?.substring(0, 35) || "Выберите файл"}
        <input
          className={styles.hiddenInput}
          type="file"
          onChange={handleFileOnChange}
          onClick={clearHtmlInput}
          accept=".xlsx"
        />
      </Button>

      <NdsDetails parsedDetails={parsedDetails} isParsing={isParsing} />
    </div>
  );
}
