import { Link } from "react-router-dom";
import styles from "./NdsSuccessSend.module.scss";

export default function NdsSuccessSend() {
  return (
    <div>
      <h2 className={styles.title}>✅ УПД успешно создан и отправлен</h2>
      <p className={styles.description}>
        Теперь нужно убедиться что УПД принято.
      </p>
      <div className={styles.description}>
        <ol>
          <li>
            Перейдите по этой{" "}
            <b>
              <Link
                target="_blank"
                to={`${process.env.REACT_APP_FAIR_SIGN_URL}dashboard`}
              >
                ссылке
              </Link>
            </b>{" "}
            и пройдите в нужную категорию товаров;
          </li>
          <li>Выберите "Эдо Лайт" ➡️ "Исходящие";</li>
          <li>
            Проверьте статус верхнего документа. <br /> После проверки
            маркетплейсом статус должен измениться на{" "}
            <b>Подписан</b>.
          </li>
        </ol>
      </div>
    </div>
  );
}
