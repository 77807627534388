import styles from "./NdsDetails.module.scss";
import { Loader } from "src/components/Loader/Loader";
import {ParseNdsNoticeFileResponse} from "../../../models/NdsNoticeModels";

interface TProps {
  parsedDetails: ParseNdsNoticeFileResponse | null;
  isParsing: boolean;
}

export default function NdsDetails({ parsedDetails, isParsing }: TProps) {
  return (
    <>
      <Loader show={isParsing} message="Анализируем файл" />
      <div>
        {parsedDetails && !isParsing && (
          <div className={styles.parsedDetails}>
            <div>Общее количество товаров: {parsedDetails.totalProducts}</div>
          </div>
        )}
      </div>
    </>
  );
}
