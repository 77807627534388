import { TextField } from "@mui/material";
import styles from "./AdditionalDetails.module.scss";
import { useEffect, useState } from "react";
import { AdditionalInfo } from "src/models/UpdModels";
import { MarketplaceType } from "src/models/Models";
import DatePickerComponent from "src/components/DatePicker/DatePickerComponent";
import Select from "react-select";

interface TProps {
  disabled?: boolean;
  onChange: (signer: AdditionalInfo | null) => void;
  isAddressMissing: boolean;
  isLegalEntity: boolean;
  marketplaceType: MarketplaceType;
}

export default function AdditionalDetails({
  disabled,
  onChange,
  isLegalEntity,
  isAddressMissing,
  marketplaceType,
}: TProps) {
  const [additionalInfo, setAdditionalInfo] = useState<AdditionalInfo>({
    firstName: "",
    lastName: "",
    middleName: "",
    jobTitle: "",
    address: "",
    warehouseName: "",
    taxRate: 0,
  });

  useEffect(() => {
    let allFieldsAreFilled = true;
    if (isAddressMissing) {
      allFieldsAreFilled =
        allFieldsAreFilled && additionalInfo.address!.length > 0;
    }

    if (isLegalEntity) {
      allFieldsAreFilled =
        allFieldsAreFilled &&
        additionalInfo.firstName.length > 0 &&
        additionalInfo.lastName.length > 0 &&
        additionalInfo.jobTitle.length > 0;
    }

    onChange(allFieldsAreFilled ? additionalInfo : null);
  }, [onChange, additionalInfo, isAddressMissing, isLegalEntity]);

  const lamodaWarehouses = [
    { label: "Софьино", value: "Софьино" },
    { label: "Быково", value: "Быково" },
  ];

  const taxRates = [
    {label: "Без НДС", value: 0},
    {label: "5%", value: 5},
    {label: "7%", value: 7},
    {label: "20%", value: 20}
  ];

  return (
    <>
      {isLegalEntity && (
        <div className={styles.two_columns_container}>
          <TextField
            label="Фамилия"
            size="small"
            disabled={disabled}
            onChange={(e) =>
              setAdditionalInfo({
                ...additionalInfo,
                lastName: e.target.value,
              })
            }
          />
          <TextField
            label="Имя"
            size="small"
            disabled={disabled}
            onChange={(e) =>
              setAdditionalInfo({
                ...additionalInfo,
                firstName: e.target.value,
              })
            }
          />
          <TextField
            label="Отчество"
            size="small"
            disabled={disabled}
            onChange={(e) =>
              setAdditionalInfo({
                ...additionalInfo,
                middleName: e.target.value,
              })
            }
          />
          <TextField
            label="Должность"
            size="small"
            disabled={disabled}
            onChange={(e) =>
              setAdditionalInfo({
                ...additionalInfo,
                jobTitle: e.target.value,
              })
            }
          />
        </div>
      )}
      {isAddressMissing && (
        <div className={styles.full_width_container}>
          <TextField
            label="Адрес юр. лица"
            size="small"
            disabled={disabled}
            onChange={(e) =>
              setAdditionalInfo({ ...additionalInfo, address: e.target.value })
            }
          />
        </div>
      )}
      {marketplaceType === MarketplaceType.Lamoda && (
        <>
          <div className={styles.two_columns_container}>
            <Select
              options={lamodaWarehouses}
              placeholder="Склад"
              onChange={(option) =>
                setAdditionalInfo({
                  ...additionalInfo,
                  warehouseName: option!.value,
                })
              }
              isDisabled={disabled}
              className={styles.select}
            />
            <Select
              options={taxRates}
              placeholder="НДС"
              onChange={(option) =>
                setAdditionalInfo({
                  ...additionalInfo,
                  taxRate: option!.value,
                })
              }
              isDisabled={disabled}
              className={styles.select}
            />
          </div>

          <div className={styles.two_columns_container}>
            <TextField
              label="Номер договора"
              size="small"
              disabled={disabled}
              onChange={(e) =>
                setAdditionalInfo({
                  ...additionalInfo,
                  agreementNumber: e.target.value,
                })
              }
            />
            <DatePickerComponent
              onChange={(date) => {
                setAdditionalInfo({
                  ...additionalInfo,
                  agreementDate: date,
                });
              }}
              placeholder="Дата договора"
            ></DatePickerComponent>
          </div>
        </>
      )}
    </>
  );
}
