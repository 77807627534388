import {Button} from "@mui/material";
import {useEffect, useState} from "react";
import styles from "./NdsSender.module.scss";
import {CustomIcon} from "../../customIcon";
import FlashCertificateSelector from "../../FlashCertificateSelector/FlashCertificateSelector";
import {getAccessTokenInfo, prepareUpd, sendUpd} from "src/services/NdsNoticeApiService";
import {toast} from "react-toastify";
import axios from "axios";
import {AccessTokenInfo, ActionType, MarketplaceType, ServerErrors,} from "src/models/Models";
import {Certificate, createSignature} from "crypto-pro";
import {Link, useParams} from "react-router-dom";
import AccessTokenStatistics from "../../AccessTokenStatistics/AccessTokenStatistics";
import {autoCloseOptions, createOrUpdateInfoToast,} from "src/utils/toastExtensions";
import {AdditionalInfo, SignerInfo} from "src/models/UpdModels";
import {Loader} from "src/components/Loader/Loader";
import WbNdsNotice from "../WbNdsNotice/WbNdsNotice";
import Select from "react-select";
import AdditionalDetails from "../../AdditionalDetails/AdditionalDetails";
import NdsUploader from "../NdsUploader/NdsUploader";

type RouteParams = {
    token: string;
};

export default function NdsSender() {
    const [trueApiToken, setTrueApiToken] = useState<string>();
    const [certificate, setCertificate] = useState<Certificate>();
    const [isParsing, setIsParsing] = useState<boolean>(false);
    const [isTokenValid, setIsTokenValid] = useState<boolean>(true);
    const [uploadedFile, setUploadedFile] = useState<File>();
    const [additionalInfo, setAdditionalInfo] = useState<AdditionalInfo>();
    const [isLegalEntity, setIsLegalEntity] = useState<boolean>(false);
    const [isAddressMissing, setIsAddressMissing] = useState<boolean>(false);
    const [sentCount, setSentCount] = useState<number>(0);
    const [taxRate, setTaxRate] = useState<number>(5);
    const [marketplaceType, setMarketplaceType] = useState<MarketplaceType>(
        MarketplaceType.Wildberries
    );
    const [updActionType, setUpdActionType] = useState<ActionType>(
        ActionType.SignAndSend
    );
    const [tokenInfo, setTokenInfo] = useState<AccessTokenInfo>();
    const [sellerInn, setSellerInn] = useState<string>();
    const {token} = useParams<RouteParams>();

    const taxRates = [
        {label: "5%", value: 5},
        {label: "7%", value: 7},
        {label: "20%", value: 20}
    ];

    useEffect(() => {
        (async () => {
            if (!token) {
                setIsTokenValid(false);
                return;
            }

            let info = await getAccessTokenInfo(token);
            setIsTokenValid(info.isValid);
            setTokenInfo(info);

            createOrUpdateInfoToast("info", <AccessTokenStatistics info={info}/>, {
                position: "top-left",
                toastId: "info",
                closeButton: true,
                icon: false,
            });

            createOrUpdateInfoToast(
                "adv",
                <div>
                    <b>НОВАЯ функция бота</b>:
                    <br/>
                    ✅ Проверка статусов КИЗ;
                    <br/>
                    ✅ Вывод КИЗ из оборота;
                    <br/>
                    ✅ Возврат КИЗ в оборот;
                    <br/>
                    <br/>
                    Так же в 3 клика🔥
                    <br/>
                    Подробнее{" "}
                    <Link target="_blank" to="https://t.me/eazycheezychannel/24">
                        в телеграм канале
                    </Link>
                </div>,
                {
                    position: "top-left",
                    toastId: "adv",
                    closeButton: true,
                    icon: false,
                }
            );
        })();
    }, [token, sentCount]);

    async function handleOnClick() {
        if (clickButtonDisabled()) return;

        try {
            setIsParsing(true);

            let signerInfo = await getSignerInfo();

            let prepareUpdResult = await prepareUpd(
                trueApiToken!,
                uploadedFile!,
                token!,
                marketplaceType,
                signerInfo,
                isLegalEntity,
                taxRate!,
                sellerInn
            );

            let signature = await createSignature(
                certificate!.thumbprint,
                prepareUpdResult.encryptedContent
            );

            await sendUpd(
                trueApiToken!,
                prepareUpdResult.encryptedContent,
                prepareUpdResult.fileName,
                token!,
                signature,
                marketplaceType,
                updActionType
            );

            const shouldRedirect =
                !!tokenInfo?.allowedUseCount &&
                tokenInfo.allowedUseCount - tokenInfo.usedCount === 1;
            if (shouldRedirect) {
                window.location.href = "/upd/last-upd-sent";
                return;
            }

            switch (updActionType) {
                case ActionType.SignAndSend:
                    toast.success(
                        <div>
                            УПД успешно подписан и отправлен в Честный Знак.
                            <br/>
                            <Link target="_blank" to="/nds-notice/success-send">
                                Как проверить?
                            </Link>{" "}
                        </div>
                    );
                    break;
                case ActionType.Download:
                    toast.success(
                        "УПД успешно отправлен в ваш телеграм",
                        autoCloseOptions
                    );
                    break;
            }

            clearFileInputs();
        } catch (error) {
            if (axios.isAxiosError<ServerErrors>(error)) {
                let errorData: ServerErrors =
                    error.response?.data instanceof Blob
                        ? JSON.parse(await error.response?.data.text())
                        : error.response?.data;
                toast.error(errorData.errors.join("; "));
            } else {
                console.error(error);
            }
        } finally {
            setIsParsing(false);
        }
    }

    const clearFileInputs = () => {
        setSentCount(sentCount + 1);
        setUploadedFile(undefined);
    };

    const handleMarketplaceChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const selectedMarketplace = event.target.value as MarketplaceType;
        setMarketplaceType(selectedMarketplace);
        setUploadedFile(undefined);
        setIsLegalEntity(false);
        setIsAddressMissing(false);
    };

    const handleUpdActionTypeChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const selectedUpdActionType = event.target.value as ActionType;
        setUpdActionType(selectedUpdActionType);
    };

    async function getSignerInfo() {
        if (isLegalEntity) {
            return {
                firstName: additionalInfo!.firstName,
                lastName: additionalInfo!.lastName,
                middleName: additionalInfo!.middleName,
                jobTitle: additionalInfo!.jobTitle,
                address: additionalInfo!.address,
            } as SignerInfo;
        }

        let ownerInfo = await certificate!.getOwnerInfo();

        console.log(ownerInfo);
        let lastName = ownerInfo
            .filter((x) => x.title === "Фамилия")[0]
            .description.trim();
        let firstMiddleName = ownerInfo
            .filter((x) => x.title === "Имя Отчество")[0]
            .description.split(" ");
        let firstName = firstMiddleName[0].trim();
        let middleName =
            firstMiddleName.length > 1
                ? firstMiddleName.slice(1).join(" ").trim()
                : null;

        return {
            firstName: firstName,
            lastName: lastName,
            middleName: middleName,
            address: additionalInfo?.address,
        } as SignerInfo;
    }

    function clickButtonDisabled(): boolean {
        return (
            !trueApiToken ||
            !uploadedFile ||
            !taxRate ||
            isParsing ||
            (shouldAskAdditionalDetails(
                isLegalEntity,
                isAddressMissing
            ) && !additionalInfo)
        );
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>УПД по НДС в 3 шага</div>

            <div className={styles.actions}>
                {!isTokenValid && (
                    <div className={styles.error}>
                        Исчерпан лимит отправок или ссылка недействительна. Чтобы отправить
                        УПД запросите новую ссылку в{" "}
                        <Link target="_blank" to={`${process.env.REACT_APP_TG_BOT_URL}`}>
                            боте
                        </Link>
                    </div>
                )}

                <div>
                    <div className={styles.actionCardHeader}>
                        <CustomIcon name="key" className={styles.icon}/>
                        <div className={styles.stepLabel}>Выберите сертификат</div>
                    </div>

                    <FlashCertificateSelector
                        onChange={(token, selectedCertificate, inn) => {
                            setTrueApiToken(token);
                            setCertificate(selectedCertificate);
                            setSellerInn(inn);
                        }}
                        disabled={!isTokenValid}
                    />
                </div>

                <div>
                    <div className={styles.actionCardHeader}>
                        <CustomIcon name="key" className={styles.icon}/>
                        <div className={styles.stepLabel}>Выберите ваш НДС</div>
                    </div>

                    <Select
                        options={taxRates}
                        placeholder="НДС"
                        onChange={(option) => setTaxRate(option!.value)}
                        isDisabled={!isTokenValid}
                        className={styles.select}
                    />
                </div>

                <div className={styles.marketplaces}>
                    <label>
                        <input
                            type="radio"
                            name="marketplaceType"
                            value={MarketplaceType.Wildberries}
                            onChange={handleMarketplaceChange}
                            checked={marketplaceType === MarketplaceType.Wildberries}
                            disabled={!isTokenValid}
                        />
                        <span className={styles.wildberries}>Wildberries</span>
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="marketplaceType"
                            value={MarketplaceType.Ozon}
                            onChange={handleMarketplaceChange}
                            checked={marketplaceType === MarketplaceType.Ozon}
                            disabled={!isTokenValid}
                        />
                        <span className={styles.ozon}>Ozon</span>
                    </label>
                </div>

                {(marketplaceType === MarketplaceType.Wildberries) && (
                    <div>
                        <div className={styles.actionCardHeader}>
                            <CustomIcon name="document" className={styles.icon}/>
                            <div className={styles.stepLabel}>Загрузите уведомление о выкупе</div>
                        </div>

                        <WbNdsNotice
                            key={sentCount}
                            onChange={(
                                file,
                                isLegal,
                                isAddressEmpty
                            ) => {
                                setUploadedFile(file);
                                setIsLegalEntity(isLegal);
                                setIsAddressMissing(isAddressEmpty);
                            }}
                            token={token!}
                            inn={sellerInn!}
                            trueApiToken={trueApiToken}
                            disabled={!isTokenValid || !certificate}
                        />
                    </div>
                )}

                {(marketplaceType === MarketplaceType.Ozon) && (
                    <div>
                        <div className={styles.actionCardHeader}>
                            <CustomIcon name="document" className={styles.icon}/>
                            <div className={styles.stepLabel}>Загрузите УПД по НДС</div>
                        </div>

                        <NdsUploader
                            key={`${sentCount}-${marketplaceType}`}
                            onChange={(file, isLegal) => {
                                setUploadedFile(file);
                                setIsLegalEntity(isLegal);
                            }}
                            token={token!}
                            trueApiToken={trueApiToken}
                            disabled={!isTokenValid || !certificate}
                            marketplace={marketplaceType}
                        />
                    </div>
                )}

                {shouldAskAdditionalDetails(
                    isLegalEntity,
                    isAddressMissing
                ) && (
                    <div>
                        <div className={styles.actionCardHeader}>
                            <CustomIcon name="person" className={styles.icon}/>
                            <div className={styles.stepLabel}>
                                Введите дополнительные данные
                            </div>
                        </div>

                        <AdditionalDetails
                            key={`${sentCount}-${uploadedFile?.name}`}
                            onChange={(info) => {
                                setAdditionalInfo(info ?? undefined);
                            }}
                            disabled={!isTokenValid}
                            isAddressMissing={isAddressMissing}
                            isLegalEntity={isLegalEntity}
                            marketplaceType={marketplaceType}
                        />
                    </div>
                )}

                <div>
                    <div className={styles.actionCardHeader}>
                        <CustomIcon name="updAction" className={styles.icon}/>
                        <div className={styles.stepLabel}>Что сделать с УПД</div>
                    </div>

                    <div className={styles.updActionTypes}>
                        <label>
                            <input
                                type="radio"
                                name="updActionType"
                                value={ActionType.SignAndSend}
                                onChange={handleUpdActionTypeChange}
                                checked={updActionType === ActionType.SignAndSend}
                                disabled={!isTokenValid}
                            />
                            <span>Подписать и отправить</span>
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="updActionType"
                                value={ActionType.Download}
                                onChange={handleUpdActionTypeChange}
                                checked={updActionType === ActionType.Download}
                                disabled={!isTokenValid}
                            />
                            <span>Только скачать</span>
                        </label>
                    </div>
                </div>

                <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={handleOnClick}
                    className={styles.sendButton}
                    disabled={clickButtonDisabled()}
                >
                    <div className={styles.sendButtonText}>
                        {updActionType === ActionType.SignAndSend ? "Отправить" : "Скачать"}
                    </div>
                </Button>

                <Loader
                    show={isParsing}
                    message={
                        updActionType === ActionType.SignAndSend
                            ? "Отправляем УПД в Честный Знак"
                            : "Скачиваем УПД"
                    }
                />
            </div>
        </div>
    );
}

function shouldAskAdditionalDetails(
    isLegalEntity: boolean,
    isAddressMissing: boolean
) {
    return (
        isLegalEntity ||
        isAddressMissing
    );
}
