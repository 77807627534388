import axios from "axios";
import {AccessTokenInfo, MarketplaceType, ActionType} from "../models/Models";
import {
  ParseUpdFileResponse,
  PrepareUpdResponse,
  SignerInfo,
} from "src/models/UpdModels";
import {Dayjs} from "dayjs";

const baseUrl = process.env.REACT_APP_API_URL;

export const getAccessTokenInfo = async (
  token: string
): Promise<AccessTokenInfo> => {
  var response = await axios.get(`upd/token/${token}`, {
    baseURL: baseUrl,
  });

  return response.data;
};

export const parseWbAct = async (
  file: File,
  token: string,
  trueApiToken: string
): Promise<ParseUpdFileResponse> => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("token", token);

  const response = await axios.post("upd/parse/wb", formData, {
    baseURL: baseUrl,
    headers: {TrueApiToken: trueApiToken},
  });

  return response.data;
};

export const parseLamodaAct = async (
  file: File,
  token: string,
  sellerInn: string,
  trueApiToken: string
): Promise<ParseUpdFileResponse> => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("token", token);
  formData.append("sellerInn", sellerInn);

  const response = await axios.post("upd/parse/lamoda", formData, {
    baseURL: baseUrl,
    headers: {TrueApiToken: trueApiToken},
  });

  return response.data;
};

export const parseUpd = async (
  file: File,
  token: string,
  trueApiToken: string
): Promise<ParseUpdFileResponse> => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("token", token);

  const response = await axios.post("upd/parse/xml", formData, {
    baseURL: baseUrl,
    headers: {TrueApiToken: trueApiToken},
  });

  return response.data;
};

export const prepareUpd = async (
  trueApiToken: string,
  file: File,
  token: string,
  marketplaceType: MarketplaceType,
  signer: SignerInfo,
  isLegalEntity: boolean,
  sellerInn?: string,
  agreementNumber?: string,
  agreementDate?: Dayjs,
  warehouseName?: string,
  taxRate?: number
): Promise<PrepareUpdResponse> => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("token", token);
  formData.append("firstName", signer.firstName);
  formData.append("lastName", signer.lastName);
  if (!!signer.middleName) formData.append("middleName", signer.middleName);
  if (!!signer.jobTitle) formData.append("jobTitle", signer.jobTitle);
  if (!!signer.address) formData.append("address", signer.address);
  formData.append("isLegalEntity", isLegalEntity.toString());
  if (!!sellerInn) formData.append("sellerInn", sellerInn);
  if (!!agreementNumber) formData.append("agreementNumber", agreementNumber);
  if (!!agreementDate)
    formData.append("agreementDate", agreementDate.format("YYYY-MM-DD"));
  if (!!warehouseName) formData.append("warehouseName", warehouseName);
  if (!!taxRate) formData.append("taxRate", taxRate.toString());
  formData.append("marketplaceType", marketplaceType);

  let path = "";
  switch (marketplaceType) {
    case MarketplaceType.Wildberries:
    case MarketplaceType.Lamoda:
      path = "upd/prepare/excel";
      break;
    case MarketplaceType.Ozon:
    case MarketplaceType.DetMir:
    case MarketplaceType.SberMegaMarket:
    case MarketplaceType.YandexMarket:
      path = "upd/prepare/xml";
      break;
  }

  const response = await axios.post(path, formData, {
    baseURL: baseUrl,
    headers: {TrueApiToken: trueApiToken},
  });

  return response.data;
};

export const sendUpd = async (
  trueApiToken: string,
  encryptedContent: string,
  fileName: string,
  token: string,
  signature: string,
  marketplaceType: MarketplaceType,
  actionType: ActionType
): Promise<string> => {
  let body = {
    encryptedContent: encryptedContent,
    fileName: fileName,
    token: token,
    signature: signature,
    marketplaceType: marketplaceType,
    actionType: actionType,
  };
  const response = await axios.post("upd/send", body, {
    baseURL: baseUrl,
    headers: {TrueApiToken: trueApiToken},
  });

  return response.data;
};
